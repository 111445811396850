import React, { ReactNode, FunctionComponent } from 'react'
import classnames from 'classnames'

import styles from './styles.module.scss'

type WrapperProps = {
  children: ReactNode
  visible: boolean
}

const Wrapper: FunctionComponent<WrapperProps> = ({ children, visible }) => {
  return (
    <div className={classnames(styles.wrapper, { [styles.visible]: visible })}>
      {children}
    </div>
  )
}

export default Wrapper
