import React, { FunctionComponent, Fragment } from 'react'
import { useRecoilState } from 'recoil'

import Wrapper from 'components/wrapper'
import Landing from 'components/landing'
import Background from 'components/background'
import { neuralState } from 'state/atoms'

const App: FunctionComponent = () => {
  const [neural] = useRecoilState(neuralState)
  return (
    <Fragment>
      <Background />
      <Wrapper visible={!!neural.image}>
        <Landing />
      </Wrapper>
    </Fragment>
  )
}

export default App
