import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { getInitialLanguage } from 'modules/i18n/preserve'

import styles from './styles.module.scss'

type MenuProps = {}

const Menu: FunctionComponent<MenuProps> = () => {
  const { t } = useTranslation()
  let language = getInitialLanguage()

  const changeLanguage = () => {
    const newLanguage = language === 'EN' ? 'RU' : 'EN'
    i18next.changeLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)
    language = newLanguage
  }

  return (
    <div className={styles.menu}>
      <div className={styles.title}>UST</div>
      <a
        target="_blank"
        rel="noreferrer"
        className={styles.link}
        href="https://twitter.com/AlexAnanin"
      >
        {t('Твиттер')}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        className={styles.link}
        href="/files/UST ES.pdf"
      >
        {t('About')}
      </a>

      {false && (
        <div className={styles.language} onClick={changeLanguage}>
          <span className={language === 'EN' ? styles.bold : ''}>en</span>
          <span className={styles.slash}> / </span>
          <span className={language === 'RU' ? styles.bold : ''}>ru</span>
        </div>
      )}
    </div>
  )
}

export default Menu
