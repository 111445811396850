import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'

import logo from './logo.png'

type LogoProps = {
  visible: boolean
}

const Logo: FunctionComponent<LogoProps> = ({ visible }) => {
  return (
    <img
      className={classnames(styles.logo, { [styles.visible]: visible })}
      alt="United Space Team"
      src={logo}
    />
  )
}

export default Logo
