import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import Logo from 'atoms/logo'
import Menu from 'components/menu'
import Button from 'atoms/button'
import styles from './styles.module.scss'

type LandingProps = {}

const Landing: FunctionComponent<LandingProps> = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.landing}>
      <div className={styles.menu}>
        <Menu />
      </div>
      <div className={styles.logo}>
        <Logo visible={true} />
      </div>
      <div className={styles.private}>{t('We are in stealth')}</div>
      <div className={styles.links}>
        <div className={styles.connect}>
          <Button
            link="https://www.linkedin.com/in/alex-%E2%80%9Cgiorgio%E2%80%9D-ananin-93365a201/"
            text={t('Connect')}
          />
        </div>
        <div className={styles.twitter}>
          <Button link="https://twitter.com/AlexAnanin" text={t('Twitter')} />
        </div>

        <div className={styles.signin}>
          <Button
            link="https://unitedspaceteam.com/members"
            text={t('Members')}
          />
        </div>
      </div>
    </div>
  )
}

export default Landing
