export const getInitialLanguage = () => {
  const languages: any = { EN: 'EN', RU: 'RU' }
  let language = ''
  try {
    // Try get from store
    language = localStorage.getItem('language') || ''
    if (!language) {
      // Try get from browser
      language = (
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        ''
      )
        .split('-')[0]
        .toUpperCase()
    }
  } catch (e) {}
  // Return only existing languages
  return languages[language] || ''
}
