import React, { FunctionComponent } from 'react'

import styles from './styles.module.scss'

type ButtonProps = {
  text: string
  link: string
}

const Button: FunctionComponent<ButtonProps> = ({ text, link }) => {
  return (
    <a className={styles.button} href={link}>
      {text}
    </a>
  )
}

export default Button
