import React, { FunctionComponent, Fragment } from 'react'
import styles from './styles.module.scss'

import { useRecoilState } from 'recoil'
import { neuralState } from 'state/atoms'

import { loadImage, networkImageData } from 'modules/convnet'

const imageUrl = `/images/crop/${Math.floor(Math.random() * 12 + 1)}.jpg`

loadImage(imageUrl)

let nowImage = 1

type BackgroundProps = {}

const Background: FunctionComponent<BackgroundProps> = () => {
  const [neural, setNeural] = useRecoilState(neuralState)

  setInterval(() => {
    const background1 = document.getElementById(
      'background1'
    ) as HTMLImageElement
    const background2 = document.getElementById(
      'background2'
    ) as HTMLImageElement
    if (!background1 || !background2 || !networkImageData) {
      return
    }
    if (!neural.image) {
      setNeural({ image: true })
    }

    const background = nowImage === 1 ? background1 : background2
    nowImage = 3 - nowImage
    // background.style.backgroundImage = `url('${networkImageData}')`
    background.src = networkImageData
  }, 200)

  return (
    <Fragment>
      <img alt={''} className={styles.background} id="background1" />
      <img alt={''} className={styles.background} id="background2" />
    </Fragment>
  )
}

export default Background
